import React, { useState, useRef, useEffect } from 'react';
import { DisLikeIcon, LikeIcon } from '../../shared/icon-svg/icon';
// import {updateEntity as updateGeneratedMessage} from '../../../../../app/entities/generated-message/generated-message.reducer'
// import { userReaction } from '../../entities/ai-chat/ai-chat.reducer';
import { updateEntity as updateUserReaction } from '../../entities/generated-message/generated-message.reducer';
import { useAppDispatch } from '../../../../../app/config/store';
import { userChatId as getUserChatId } from '../../entities/generated-message/generated-message.reducer';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const LikeDislikeButton = ({ message, userNameInital, aiResp, cta, chatId = null }: any) => {
  const [aiResponse, setAiResponse] = useState<any>();
  const [showButtons, setShowButtons] = useState<boolean>(cta);
  const [messageContext, setMessageContext] = useState<any>();
  const [result, setResult] = useState<any>();
  const [showReasons, setShowReasons] = useState(false);
  const [inputData, setInputData] = useState('');
  const [messId, setMessId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const IconWithTooltip = ({ id, tooltipText }) => {
    const [iconTooltipOpen, setIconTooltipOpen] = useState(false);
    const toggle = () => setIconTooltipOpen(!iconTooltipOpen);
    return (
      <Tooltip placement="bottom" isOpen={iconTooltipOpen} target={id} toggle={toggle}>
        {tooltipText}
      </Tooltip>
    );
  };

  useEffect(() => {
    setAiResponse(aiResp);
  }, [aiResp]);

  useEffect(() => {
    if (cta) {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    setShowButtons(cta);
  }, [cta]);

  const udpdateReaction = async obj => {
    try {
      dispatch(updateUserReaction(obj));
    } catch (e) {
      console.log(e);
    }
  };
  const getResult = async () => {
    const { payload } = await dispatch(getUserChatId(chatId));
    // setResult(payload.data);
    setMessageContext({ id: payload?.data?.data?.messageId, userReaction: 'Neutral' });
  };
  useEffect(() => {
    if (chatId != null) {
      getResult();
      // setMessageContext({ id: result?.data.messageId, userReaction: 'Neutral' })
    }
  }, [chatId]);

  useEffect(() => {
    setMessageContext(message);
  }, [message]);

  const handleAction = (type: 'Liked' | 'Disliked') => {
    let tempStatus;
    if (messageContext?.userReaction === type) {
      tempStatus = null;
    } else {
      tempStatus = type;
    }
    if (type == 'Disliked') {
      setShowReasons(messageContext?.userReaction !== 'Disliked' ? !showReasons : false);
    } else if (type == 'Liked') {
      setShowReasons(false);
    }
    setMessageContext(prevMessageContext => {
      return { ...prevMessageContext, userReaction: tempStatus == null ? 'Neutral' : tempStatus };
    });
    udpdateReaction({ id: messageContext.id, userReaction: tempStatus == null ? 'Neutral' : tempStatus });
    setMessId(messageContext.id);
  };

  // const allFieldsFilled = Object.values(inputData).every((value) => value.trim() !== '');

  const handleFeedback = e => {
    e.preventDefault();
    if (!inputData.trim()) {
      setErrorMessage('Please enter valid reason');
    } else {
      setErrorMessage('');
      udpdateReaction({ id: messageContext.id, userRatingReason: inputData.trim() });
      setShowReasons(false);
    }
  };
  const handleClearShowReason = () => {
    setShowReasons(false);
  };
  const renderUserMessage = () => (
    <div className="usertext">
      <strong>
        <span>{userNameInital}</span> You
      </strong>
      {message?.userPrompt?.promptText ?? message?.aiChat?.title ?? message}
    </div>
  );

  const renderAIResponse = () => (
    <div className="cpaText">
      <strong>
        <img className="cpapilot-chat-logo" src="content/images/logo.png" alt="Logo" />
        CPA Pilot
      </strong>
    </div>
  );

  // New useEffect to scroll when 'showReasons' state changes
  useEffect(() => {
    if (showReasons && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [showReasons]); // Trigger scroll when showReasons state changes

  return (
    <>
      {userNameInital && message && [renderUserMessage(), renderAIResponse()]}
      {aiResponse?.props?.children !== false && aiResponse}
      {/* {aiResp} */}
      {showButtons && (
        <div className="">
          <button
            className={`btn ${messageContext?.userReaction == 'Liked' ? 'active' : ''}`}
            onClick={() => handleAction('Liked')}
            style={{ border: 'none' }}
          >
            <LikeIcon iconId={'LikedIcon'} color={messageContext?.userReaction == 'Liked' ? 'orange' : 'black'} />
            <IconWithTooltip id="LikedIcon" tooltipText="Like" />
          </button>
          <button
            className={`btn ${messageContext?.userReaction == 'Disliked' ? 'active' : ''}`}
            onClick={() => handleAction('Disliked')}
            style={{ border: 'none' }}
          >
            <DisLikeIcon iconId={'DisLikeIcon'} color={messageContext?.userReaction == 'Disliked' ? 'orange' : 'black'} />
            <IconWithTooltip id="DisLikeIcon" tooltipText="Dislike" />
          </button>
        </div>
      )}
      {showReasons && messId == messageContext?.id && (
        <div className="border rounded w-50 py-4 px-3">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <p>Why is this a bad response?</p>
            <button style={{ border: 'none', backgroundColor: 'white', marginBottom: '10px' }} onClick={handleClearShowReason}>
              {/* &times; */}
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <form>
            <div className="flex gap-2">
              <input
                type="text"
                placeholder="Provide Feedback"
                style={{ width: '500px', border: '2px solid #ccc', outline: 'none', padding: '4px', borderRadius: '5px' }}
                onChange={e => setInputData(e.target.value)}
              />
              <button style={{ border: '3px solid #ccc', borderRadius: '5px' }} onClick={e => handleFeedback(e)}>
                Submit
              </button>
            </div>
            {errorMessage && <div style={{ color: 'Red' }}>{errorMessage}</div>}
          </form>
        </div>
      )}
      {/* Empty div that will be scrolled to */}
      <div ref={messagesEndRef}></div>
    </>
  );
};

export default LikeDislikeButton;
